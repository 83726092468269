body {
  @extend .font-style-body;

  margin: 0;
  min-width: $layout-min-width;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $color-content;
  background-color: $color-background;

  overflow-x: hidden;

  justify-content: center;
  margin: 0;
  width: 100%;

  //body.no-scroll
  &.no-scroll {
    position: fixed;
    overflow-y: scroll;
  }

  //body.not-found-page
  &.is-not-found-page {
    background-image: url('~images/not-found-illustration-background.svg');
    background-position: center bottom;
    background-repeat: no-repeat;
  }
}

a {
  color: $color-content;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


// TODO: move this styles to the respectives’ object styles
input,
button {
  @extend .font-style-body;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-main {
  flex-grow: 1;
  padding-top: $layout-unit * 6 +
    $layout-unit * 5 +
    $layout-navbar-border-width +
    $layout-view-vertical-padding;
  padding-bottom: $layout-view-vertical-padding;

  @include media-breakpoint-up(md) {
    padding-top: $layout-navbar-content-height +
      ($layout-navbar-vertical-padding * 2) +
      $layout-navbar-border-width +
      $layout-view-vertical-padding;
    padding-bottom: $layout-view-vertical-padding;
  }

  //.has-cover-image .app-main
  .has-cover-image & {
    padding-top: $layout-unit * 6 +
      ($layout-unit * 5 * 2) +
      $layout-navbar-border-width;

    @include media-breakpoint-up(md) {
      padding-top: $layout-unit * 6 +
        ($layout-unit * 5 * 2) +
        $layout-navbar-border-width +
        $layout-view-vertical-padding/2;
    }
  }

  //.is-not-found-page .app-main
  .is-not-found-page & {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  //.is-new-order-logged-out-page .app-main
  .is-new-order-logged-out-page & {
    overflow: hidden;
  }
}
