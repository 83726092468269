@import "~styles/prepend";
.push-notifications {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  max-width: 352px;
  margin: $layout-unit-medium;
  z-index: 5;

  //.push-notifications__notification
  &__notification {
    border: 1px solid rgba($color-gray5, 0.8);
    border-radius: $layout-default-border-radius;
    padding: 0 $layout-unit-xs;
    background-color: $color-white;
    margin-bottom: $layout-unit-xs;
    cursor: pointer;
    transition: background-color 0.2s ease;

    //.push-notifications__notification:hover
    &:hover {
      background-color: $color-gray6;
    }

    //.push-notifications__notification:last-child
    &:last-child {
      //TODO: check if this margin can be set with &:not(.last-child) { margin-bottom: $layout-unit-xs; }
      margin-bottom: 0;
    }
  }
}
